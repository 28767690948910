<template>
  <div class="admin-guidance-topics">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button type="default" style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/guidance')" icon="left"></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Guidance - Articles
      </h1>
      <div class="actions">
        <a-button @click.prevent="loadArticles" :disabled="isLoading" class="button-margin-left btn-rounded"
          icon="reload" size="large" type="default">Refresh</a-button>

        <a-button v-if="showCreateButton" @click.prevent="createArticle" class="button-margin-left btn-rounded"
          icon="plus" size="large" type="primary">Create</a-button>
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="topics-list-wrapper">
   
      <div class="admin-guidance-context-selector-wrapper">
        <admin-guidance-context-selector v-if="!isLoading"></admin-guidance-context-selector>
      </div>

      <a-alert
        style="margin-bottom: 25px"
        message="A piece of writing about a particular subject. These sit under Topics."
      >
      </a-alert>

         <!-- Loading -->
         <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No articles -->
      <a-alert v-if="!isLoading && articlesToShow.length == 0" type="info" message="No articles to show">
      </a-alert>
      <!-- / No articles -->

      <!-- Articles list -->
      <div class="articles-list" v-if="!isLoading && articlesToShow.length" :key="ownerIdFilter">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="article in articlesToShow" :key="article.id">
            <article-list-item :article="article" :tenant-id="tenantId" :org-id="selectedOrganisation.id"
              @selected="() => navigateToArticle(article)"></article-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Articles list -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ArticleListItem from './Articles/ArticleListItem.vue';
import AdminGuidanceContextSelector from "../../../components/Admin/Guidance/AdminGuidanceContextSelector.vue";
export default {
  components: { ArticleListItem, AdminGuidanceContextSelector },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadArticles();
  },

  watch: {
    selectedOrganisation() {
      this.loadArticles();
    },
  },

  methods: {
    ...mapActions("adminArticles", {
      loadArticles: "loadArticles",
    }),

    navigateToArticle(article) {
      this.$router.push("/admin/guidance/articles/" + article['$v'].id);
    },

    createArticle() {
      this.$router.push("/admin/guidance/articles/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminArticles", {
      isLoading: "isLoading",
      articlesToShow: "articlesToShow",
    }),

    ...mapGetters("adminGuidance", {
      ownerIdFilter: 'ownerIdFilter'
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    showCreateButton() {
      return (!this.ownerIdFilter || this.ownerIdFilter == this.selectedOrganisation.id)
    }
  },
};
</script>

<style scoped lang="scss">
.article-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.admin-guidance-context-selector-wrapper {
  margin-bottom: 30px;
}
</style>